<template>
  <div class="app">
    <comhd></comhd>
    <div class="main">
      <md-scroll-view :scrolling-x="false" auto-reflow>
          <div class="zycover">
            <img :src="zyimg" alt="" style="height: 100%;width: 100%;object-fit: cover">
            <div @click="$router.push({name:'zyreg'})">注册成为志愿者</div>
          </div>

         <div v-for="arr of newList.slice(0,3)" :key="arr.name">
           <div class="tuhd">
             <div>{{arr.name}}</div>
             <div class="more" @click="$router.push({name:'List',query:{cid:arr.cid}})">更多
               <md-icon name="arrow-right" size="mini"/>
             </div>
           </div>
           <div class="zbList">
             <div class="zbItem" v-for="item of arr.list" :key="item.aid" @click="$router.push({name:'detail',query:{id:item.aid}})">
               <div class="cover">
                 <img :src="item.cover" alt="">
               </div>
               <div class="info">
                 <div class="title">{{ item.title.slice(0,28) }}</div>
                 <div class="detail">
                   <div class="author">{{item.author}}</div>
                   <div class="time">{{item.addTime|format('YYYY-MM-DD')}}</div>
                 </div>
               </div>
             </div>
           </div>
         </div>


        </md-scroll-view>
      </div>
  </div>



</template>

<script>
import zy from '@/assets/img/zy.webp'
export default {
name: "List",
  data(){
    return {
      zyimg:zy,
      items: [
        {name: 1, label: '默认'},
        {name: 2, label: '全部'},
        {name: 3, label: '满减券'},
        {name: 4, label: '立减券'},
        {name: 5, label: '免息券'},
        {name: 6, label: '校园专享'},
        {name: 7, label: '夜间优惠'},
        {name: 8, label: '红包'},
      ],
      orderBy: '默认',
      newList:[]
    }
  },
  created(){
    this.getData()
  },
  methods:{
    async getData(){
      let res =await  this.$api.post('/mapi/zylist')
      const {success,data} = res.data
      if(success) this.newList = data
    }
  }
}
</script>

<style lang="scss" scoped>

.main{
  height: 0;
  flex-grow: 1;
}

</style>
